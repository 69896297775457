import React from 'react';
// import Sidenave from '../Sidenave';
// import Box from '@mui/material/Box';

export default function UpdateManager() {
    return (
        <>
                <div>
                    <h1>UpdateManager</h1>
                </div>
        </>
    )
}